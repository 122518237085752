import React , { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/Logo1.png'
import './navbar.css';
const Menu = ()=>(
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#mr">My Resume</a></p>
  <p><a href="#Photo's">Photo's</a></p>
  <p><a href="#thitw">that's how it works</a></p>
  <p><a href="#footer">Contact</a></p>
  </>
)

//BEM -> Block Element Nodifier(ein Method um klasses zu nennen)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='mypro__navbar'>
      <div className='mypro__navbar-links'>
        <div className='mypro__navbar-links_logo'>
            <img src={logo} alt="logo" />
        </div>
        <div className='mypro__navbar-links_container'>
        <Menu />  
        </div> 
      </div>
      <div className='mypro__navbar-sign'> 
          <p>Sign in</p>
          <button>Sign up</button>
        </div>
        <div className='mypro__navbar-menu'>
          {toggleMenu
           ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
           : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
          }  
          {toggleMenu && (
            <div className='mypro__navbar-menu_container scale-up-center'>
              <div className='mypro__navbar-menu_container-links'>
                <Menu />
                <div className='mypro__navbar-menu_container-links-sign'> 
                    <p>Sign in</p>
                    <button>Sign up</button>
                  </div>
              </div>
            </div>
          )}
        </div>
    </div>
  )
}

export default Navbar