import React from 'react'

import Article from './components/article/Article';
import Brand from './components/brand/Brand';
import CTA from './components/cta/CTA';
import Feature from './components/feature/Feature';
import Navbar from './components/navbar/Navbar';
import { Footer,Header, Blog, Possibility, Features, WGdas } from './containers';
import './App.css'

const App = () => {
  return (
    <div className='App'>
        
        <div className='gradient__bg2'>
            <Navbar />
            <Header />
           
        </div>
        <Brand />
        <WGdas />
        <Footer />
      
    </div>
  )
}

export default App
