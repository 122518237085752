import React from 'react'
import Feature from '../../components/feature/Feature'
import './WGdas.css'

const WGdas = () => {
  return (
    <div className='mypro__WGdas section__margin' id='thitw'>
      <div className='mypro__WGdas-feature'>
        <Feature titel='How does it work?' text='ouer Proccess will started with....' />
      </div>
      <div className='mypro__WGdas-heading'>
        <h1 className='gradient__text'>that's how it works</h1>
        <p>Explore The Library</p>
      </div>
      <div className='mypro__WGdas-container'>
        <Feature titel={'professional Consultation'} text={`In this initial step, you collaborate with web design experts to define your website’s goals, target audience, and features. This consultation ensures that the development process is aligned with your business objectives.

                                                              Key Actions:
                                                               .Discuss your business needs and goals
                                                               .Define your audience, purpose, and website features
                                                               .Choose a platform (WordPress, custom development, etc.)
                                                               .Review budget and timeline expectations.`} />
        <Feature titel={'Receive the initial design'} text={`After understanding your needs, designers create a basic design layout. This design often includes wireframes or mockups, providing a visual draft of your website's structure and user interface.

                                                              Key Elements:
                                                               .A homepage layout, navigation menus, and content structure.
                                                               .Wireframes of key sections (e.g., services, contact, about).
                                                               .Color schemes, typography, and branding elements.
                                                               .Feedback from you on aesthetics and design flow.`} />
        <Feature titel={'personalization'} text={`Now, the design is customized based on your feedback. Content, images, and other elements are added, making the website unique to your brand. Functionality is integrated (forms, e-commerce, etc.) according to your business needs.

                                                              Key Personalizations:
                                                               .Tailoring text, images, and branding.
                                                               .Adding features like blogs, contact forms, or product listings.
                                                               .Ensuring mobile responsiveness and SEO optimization.
                                                               .Testing website functionality and user experience.`} />
        <Feature titel={'Support'} text={`Once the site is live, ongoing support ensures the website runs smoothly and stays updated. The support team monitors performance, fixes bugs, updates software, and ensures the site’s security.

                                            Support Includes:
                                             .Technical support for any website issues.
                                             .Regular updates and security patches.
                                             .Adding new features or adjustments based on user feedback.
                                             .Ongoing SEO optimization and performance monitoring.`} />
      </div>

    </div>
  )
}

export default WGdas