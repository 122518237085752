import React from 'react'
import './footer.css'
import mylogo from '../../assets/Logo1.png'

const Footer = () => {
  return (
    <div className='mypro__footer section__padding' id='footer'>
      <div className='mypro__footer-heading'>
        <h1 className='gradient__text'>Do you want to Step in to the future be for other</h1>
      </div>

      <div className='mypro__footer-btn'>
        <p>Request Eaely Accesss</p>
      </div>

      <div className='mypro__footer-links'>
        <div className='mypro__footer-links_logo'>
          <img src={mylogo} alt="logo" />
          <p>Pixel Plus: "The Manifestation of Your Abilities"</p>
        </div>
        <div className='mypro__footer-links_div'>
          <h4>Links</h4>
          <p>Overons</p>
          <p>Counters</p>
          <p>Contact</p>
          <p>Social Media</p>
        </div>
        <div className='mypro__footer-links_div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='mypro__footer-links_div'>
          <h4>Get in touch</h4>
          <p>Address</p>
          <p>Tel</p>
          <p>p.hazeri-work@gmail.com</p>
        </div>
      </div>
 {/*alt 0169  */}
      <div className='mypro__footer-copyright'>
          <p>© 2024 Pixel Plus All rights Reserved</p>
      </div>

    </div>
  )
}

export default Footer