import React from 'react'
import './header.css'
import people from "../../assets/people.png"
import ai from "../../assets/Illustration.png"

const Header = () => {
  return (
    <div className='mypro__header section__padding' id='home'>
      <div className='mypro__header-content'>
      <h1 className="gradient__text">
          Let’s Build Something Amazing with Web Programming For you!!
        </h1>
        <p>You can make your Own Picture of your Qualification..</p>
        <div className="mypro__header-content__input">
          <input type="email" placeholder="Please Enter your Email"/>
          <button type="button">Get Start</button>
        </div>
         {/*<div className="mypro__header-content__people">
          <img src={people} alt="People" />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>
        <div className='mypro__header-image'>
          <img src={ai} alt="ai"/>
        </div>
    </div>
  );
};

export default Header