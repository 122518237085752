import React from 'react';
import './feature.css';

const Feature = ({titel, text}) => {
  return (
    <div className='mypro__features-container__feature'>
      <div className='mypro__features-container__feature-title'>
        <div />
        <h1>{titel}</h1> 
      </div>
      
      <div className='mypro__features-container_feature-text'>
        <p style={{ whiteSpace: "pre-line" }}>{text}</p>
      </div>
    </div>
  )
}

export default Feature