import React from 'react';
import './article.css';

const Article = () => {
  return (
    <div>
      Article
    </div>
  )
}

export default Article
